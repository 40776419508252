.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #01f1d6;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #01f1d6;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

body {
  font-family: 'Jura', sans-serif !important
}

.cabecera-tabla-alumno-individual {
  background-color: #01f1d6;
}

.alumno-name-ai {
  text-align: start;
}

.alumno-gh-ai {
  display: flex;
  margin-top: 04mm;
  margin-left: 1%;
}

.container-datos-ai {
  margin-top: 5%;
}

.container-data-table-ai {
  margin-top: 3%;
}

.contenidoAlumno {
  display: flex;
}

.container-login{
  background-image:url(../src/utils/foto\ proyecto\ copia.jpg);
  height: 100vh;
  background-size: cover;
  background-position: center;
}
.formulario-login{
  width: 30%;
  
}

.logo-login{
  width: 15%;
}

.container-formulario{
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 12%;
}

.formulario-button-login{
  background-color: #01f1d6 !important;
  border: none !important;
  margin-left: 35%;
  margin-top: 5%;
  color: #fff !important;
  font-weight: 800 !important;
}

.formulario-button-login:hover{
background-color: #282c34 !important;
}

.register-link{
  text-align:center;
  margin-top: 5%;
  font-size: 15px;
}

.registro-mensaje{
  padding-right: 30% !important ;
}

.register-button{
  background-color: #01f1d6 !important;
  border: none !important;
}

.form-check-input:checked{
  background-color: #01f1d6 !important;
  border: none !important;
  box-shadow: none !important;
}

.form-check-input:focus{
 border-color: #b6b6b6 !important;
  box-shadow: none !important;
}

.btn:disabled{
  background-color: #b6b6b6!important;
}


/*TABLA Manager-Evaluacion-Grupal*/
.header-table-evaluation {
  background-color: #01f1d6 !important;
  color: black !important;
}

/*PAG Manager-Evaluacion-Grupal*/

.ManagerEvaluacion-contenedor-principal {
  max-width: 1280px;
  margin: 0 auto;
}

.ManagerEvaluacion-contenedor-title-button {
  text-align: right;
  margin-top: 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.ManagerEvaluacion-title-evaluacion {
  text-align: left;
}

.ManagerEvaluacion-selectores-principales {
  display: flex;
  margin-top: 24px;
  margin-bottom: 24px;
  row-gap: 1.5rem !important;
  column-gap: 1.5rem !important;
}

/*Botón guardar*/
.save-buttom {
  height: 37px;
  background-color: #01f1d6 !important;
  border: none !important;
  color: black !important;
}

/*Botones desplegables*/

.boton-seleccionar {
  background-color: #01f1d6 !important;
  border: none !important;
  color: black !important;
}

.opciones-del-boton-seleccionar {
  background-color: white;
}

.container-cabecera{
display: flex !important;
}

.btn-cabecera{
  margin-left: 2%;
  
}

.alineacion-btns-cabecera{
  margin-bottom: 3%;
  margin-top: 3%;
}

.btn{
  background-color: #01f1d6!important;
  border: none !important;
  color: #282c34 !important;
}
